import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'wagmi';
import App from './App';
import './index.scss';

import { connectors, provider } from './utils/blockchain';

ReactDOM.render(
  <React.StrictMode>
    <Provider connectors={connectors} provider={provider}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
