import React, { ReactElement } from 'react';

interface IModal {
  isVisible: boolean;
  bgColor?: boolean;
  children: ReactElement;
}

const Modal: React.FC<IModal> = ({ isVisible, bgColor = true, children }) => {
  return (
    <>
      {isVisible ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none px-3">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div
                className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full ${
                  bgColor ? 'bg-slate-800' : ''
                } outline-none focus:outline-none`}
              >
                {children}
              </div>
            </div>
          </div>
          <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
