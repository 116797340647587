export const ipfsLinkBuilder = (str: string) => {
  return `https://nftfactory.mypinata.cloud/ipfs/${str.split('://')[1]}`;
};

export const truncateAddress = (address: string, numchars: number = 10) => {
  const firstHalf = Math.floor(numchars / 2);
  const secondHalf = numchars - firstHalf;
  return `${address.slice(0, firstHalf + 2)}...${address.slice(
    address.length - secondHalf
  )}`;
};
