import { ethers } from 'ethers';
import { chain } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { Buffer } from 'buffer';

// polyfill Buffer for client
if (!window.Buffer) {
  window.Buffer = Buffer;
}

export const supportedChains = [
  process.env.NODE_ENV === 'production'
    ? chain.polygonMainnet
    : chain.polygonTestnetMumbai,
];

const currentChain =
  process.env.NODE_ENV === 'production'
    ? chain.polygonMainnet
    : chain.polygonTestnetMumbai;

export const connectors = [
  new InjectedConnector({
    chains: [currentChain],
  }),
  new WalletConnectConnector({
    // chains: supportedChains,
    options: {
      // rpc,
      qrcode: true,
      clientMeta: {
        name: '2048-nft',
        description: '2048-nft game',
        url: '2048-nft.com',
        icons: ['assets/logo192.png'],
      },
    },
  }),
];

export const provider = new ethers.providers.JsonRpcProvider({
  url: 'https://polygon-mainnet.g.alchemy.com/v2/dQev3JnNjnO_LbRZ966iBpVVOh1KKo9J',
});
