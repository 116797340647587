import React from 'react';

interface ISecondaryButton {
  onClick: () => void;
  title: string;
}

const SecondaryButton: React.FC<ISecondaryButton> = ({ onClick, title }) => {
  return (
    <button onClick={() => onClick()} className="btnSecondary">
      <span className="btnWrapperSecondary">
        <span className="btnText text-2xl text-black font-semibold">
          {title}
        </span>
        <img
          loading="lazy"
          src="images/glass.png"
          alt="glass"
          className="btnGlass"
          width="59px"
          height="50px"
        />
      </span>
    </button>
  );
};

export default SecondaryButton;
