import { Player } from '@lottiefiles/react-lottie-player';
import React, { useCallback, useEffect, useState } from 'react';
import { useAccount, useConnect, useContract } from 'wagmi';
import PrimaryButton from './components/PrimaryButton';

// Game
import { createGame, restartGame } from './game';
import Modal from './components/Modal';
import { ipfsLinkBuilder, truncateAddress } from './utils/helpers';
import SecondaryButton from './components/SecondaryButton';
import contractInfo from './contracts/NFT2048.json';
import { provider } from './utils/blockchain';

enum EEvent {
  GameStarted = 'gameStarted',
  GameOver = 'gameOver',
  ScoreUpdate = 'scoreUpdated',
  GameWon = 'gameWon',
}

const App: React.FC = () => {
  const [animationVisible, setAnimationVisible] = useState(true);
  const [showConnect, setShowConnect] = useState(false);
  const [showPlay, setShowPlay] = useState(false);
  const [showGame, setShowGame] = useState(false);

  const [gameWon, setGameWon] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [points, setPoints] = useState(0);

  const [loading, setLoading] = useState<any>(null);

  const tiles = [2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048];

  const [{ data: connectData }, connect] = useConnect();
  const [{ data: accountData }] = useAccount({
    fetchEns: true,
  });
  const contract = useContract({
    addressOrName: '0xd0bAEE092791dfc878F1cd4a9190B290b4d3d3ED', // process.env.REACT_APP_CONTRACT_ADDRESS!,
    contractInterface: contractInfo.abi,
    signerOrProvider: provider,
  });

  const handleConnectMetamask = useCallback(async () => {
    if (connectData.connected) return;
    connect(connectData.connectors[0])
      .then((res) => console.log(res))
      .catch((err) => console.error(err.message));
  }, [connectData.connected, connectData.connectors, connect]);

  const handleConnect = useCallback(async () => {
    if (connectData.connected) return;
    connect(connectData.connectors[1])
      .then((res) => console.log(res))
      .catch((err) => console.error(err.message));
  }, [connectData.connected, connectData.connectors, connect]);

  useEffect(() => {
    if (accountData?.address) {
      setShowConnect(false);
      setShowPlay(true);
    }
  }, [accountData?.address]);

  useEffect(() => {
    fetch('animations/loading.json')
      .then((res) => res.json())
      .then((res) => {
        setLoading(res);
      });
  }, []);

  const startGame = async () => {
    const eventCallback = (event: { eventType: string; score?: number }) => {
      if (event.eventType === EEvent.GameStarted) {
        setGameOver(false);
        setGameStarted(true);
      }

      if (event.eventType === EEvent.GameOver) {
        setGameOver(true);
      }

      if (event.eventType === EEvent.ScoreUpdate && event.score !== undefined)
        setPoints(event.score);

      if (event.eventType === EEvent.GameWon) setGameWon(true);
    };

    if (accountData?.address)
      contract
        .balanceOf(accountData.address)
        .then((r: any) => {
          console.log(Number(r));
          if (Number(r) >= 1) {
            setShowPlay(false);
            setShowGame(true);
            contract
              .tokenOfOwnerByIndex(accountData.address, 0)
              .then((token: any) =>
                contract.tokenURI(Number(token)).then((ipfs: string) => {
                  fetch(ipfsLinkBuilder(ipfs))
                    .then((response) => response.json())
                    .then(async (metadata) => {
                      const tilesLink = tiles.map((tile) => {
                        return ipfsLinkBuilder(metadata[`atlas${tile}`]);
                      });

                      createGame(
                        'gameCanvas',
                        window.location.href,
                        tilesLink,
                        2,
                        eventCallback,
                        {}
                      );
                    });
                })
              );
          }
        })
        .catch((er: any) => console.error(er.message));
  };

  return (
    <section
      className="h-screen w-screen"
      style={{ backgroundColor: animationVisible ? '#000000' : '#512886' }}
    >
      <div className="container mx-auto flex flex-col justify-center items-center h-screen max-h-screen">
        {animationVisible && (
          <Player
            src={'animations/2048.json'}
            className="h-screen"
            autoplay
            onEvent={(event) => {
              if (event === 'complete') {
                setAnimationVisible(false);
                setShowConnect(true);
              }
            }}
          />
        )}
        {!animationVisible && showConnect && (
          <div className="px-2 flex flex-col justify-center items-center">
            <h2 className="text-white text-4xl text-center">
              Connect your wallet to start play!
            </h2>

            <PrimaryButton
              title="Connect Metamask"
              onClick={handleConnectMetamask}
            />
            <SecondaryButton title="Wallet Connect" onClick={handleConnect} />
          </div>
        )}

        {!animationVisible && !showConnect && showPlay && (
          <div className="px-2 flex flex-col justify-center items-center">
            {accountData?.address && (
              <h2 className="text-white">
                {truncateAddress(accountData.address)}
              </h2>
            )}
            <PrimaryButton title="Play" onClick={startGame} />
          </div>
        )}

        {!animationVisible && !showConnect && !showPlay && showGame && (
          <div className="px-2 flex flex-col justify-center items-center">
            <h2 className="text-white text-3xl md:text-5xl">Score: {points}</h2>
            <canvas id="gameCanvas" className="mt-2 w-screen md:w-4/6" />
            <Modal isVisible={!gameStarted} bgColor={false}>
              <div className="flex items-center justify-center p-5 rounded-t-3xl">
                <Player src={loading} className="h-1/3" autoplay loop />
              </div>
            </Modal>
            <Modal isVisible={gameWon}>
              <>
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t-3xl">
                  <h3 className="text-3xl font-semibold text-white">
                    You Won with {points} points!
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-white opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      restartGame();
                      setGameOver(false);
                      setPoints(0);
                    }}
                  >
                    <svg
                      className="w-10 h-10 fill-white"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    Restart the game or visit our website!
                  </p>
                </div>
                {/*footer*/}
                <div className="flex flex-col items-center justify-center px-6 pb-6 rounded-b-3xl">
                  <PrimaryButton
                    title="Restart"
                    onClick={() => {
                      restartGame();
                      setGameOver(false);
                      setPoints(0);
                    }}
                  />
                  <PrimaryButton
                    title="NFT Factory"
                    onClick={() => {
                      window.open('https://nft-factory.club');
                    }}
                  />
                </div>
              </>
            </Modal>
            <Modal isVisible={gameOver}>
              <>
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t-3xl">
                  <h3 className="text-3xl font-semibold text-white">
                    GAME OVER!
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-white opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      restartGame();
                      setGameOver(false);
                      setPoints(0);
                    }}
                  >
                    <svg
                      className="w-10 h-10 fill-white"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    Your final score was {points}! Restart the game or visit our
                    website!
                  </p>
                </div>
                {/*footer*/}
                <div className="flex flex-col items-center justify-center px-6 pb-6 rounded-b-3xl">
                  <PrimaryButton
                    title="Restart"
                    onClick={() => {
                      restartGame();
                      setGameOver(false);
                      setPoints(0);
                    }}
                  />
                  <PrimaryButton
                    title="NFT Factory"
                    onClick={() => {
                      window.open('https://nft-factory.club');
                    }}
                  />
                </div>
              </>
            </Modal>
          </div>
        )}
      </div>
    </section>
  );
};

export default App;
