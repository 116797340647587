import Phaser from 'phaser';

const DEFAULT_BASE_URL = 'http://localhost:1313';

const X_TILES = 4;
const Y_TILES = 4;
const TOTAL_TILES = X_TILES * Y_TILES;

const TILE_SIZE = 300;
const TILE_SPACING = TILE_SIZE / 10;
const NFT_TILE_SIZE = 300;
const TILE_SCALE = TILE_SIZE / NFT_TILE_SIZE;

const NFT_FRAME_RATE = 30;
const NFT_FRAMES_COUNT = 120;

const SCREEN_WIDTH = X_TILES * TILE_SIZE + (X_TILES + 1) * TILE_SPACING;
const SCREEN_HEIGHT = Y_TILES * TILE_SIZE + (Y_TILES + 1) * TILE_SPACING;

const BACKGROUND_COLOR = 0x7130c5;
const STARTING_TILES_COUNT = 2;

const FOUR_SPAWN_PERCENT = 0.1;
const WIN_TARGET_SCORE = 2048;

const ANIM_TIME_MS = 40;

const APPLY_TINT = false;

const TINTS = [
  0xeee4da, 0xede0c8, 0xf2b179, 0xf59563, 0xf67c5f, 0xf65e3b, 0xedcf72,
  0xedc850, 0xedc53f, 0xedc22e, 0xedc33d, 0xedc22c, 0xedc11b, 0xedc00a,
  0xffc00a, 0xffc22b, 0xffc33d,
];

const EVENT_GAME_STARTED = 'gameStarted';
const EVENT_SCORE_UPDATED = 'scoreUpdated';
const EVENT_GAME_OVER = 'gameOver';
const EVENT_GAME_WON = 'gameWon';

let globalTiles = null;
let globalBaseURL = null;
let globalEventCallback = null;
let globalStartingTile = 0;

class GameScene extends Phaser.Scene {
  init() {
    this.tileMatrix = [];
    this.loading = true;
    this.gameOver = false;
    this.won = false;
    this.score = 0;
    this.tilesMoving = 0;
    this.moving = false;

    this.physics.pause();
  }

  emitEvent(event) {
    if (globalEventCallback) {
      globalEventCallback(event);
    }
  }

  preload() {
    this.load.setBaseURL(globalBaseURL);
    this.load.scenePlugin(
      'rexgesturesplugin',
      'js/rexgestureplugin.min.js',
      'rexGestures',
      'rexGestures'
    );
  }

  async loadB64Image(data, key) {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.onload = () => {
        this.textures.addSpriteSheet(key, image, {
          frameWidth: NFT_TILE_SIZE,
          frameHeight: NFT_TILE_SIZE,
        });
        resolve();
      };

      image.onerror = (err) => {
        reject(err);
      };

      console.log(`Loading ${key}...`);

      image.src = data;
    });
  }

  async loadNFTTiles(onDone) {
    const promises = [2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048].map(
      async (n) => {
        await this.loadB64Image(globalTiles[~~Math.log2(n) - 1], 'n_' + n);
      }
    );
    // for (let n = 2; n <= 2048; n <<= 1) {
    //   await this.loadB64Image(globalTiles[~~Math.log2(n) - 1], 'n_' + n);
    // }
    globalTiles = undefined;
    Promise.all(promises).then(() => {
      onDone();
    });
  }

  loadGame() {
    // Create animations
    for (let i = 2; i <= 2048; i <<= 1) {
      const textureName = 'n_' + i;
      this.anims.create({
        key: 'animate_' + textureName,
        frameRate: NFT_FRAME_RATE,
        frames: this.anims.generateFrameNumbers(textureName, {
          start: 0,
          end: NFT_FRAMES_COUNT,
        }),
        repeat: -1,
      });
    }

    for (let x = 0; x < X_TILES; x++) {
      this.tileMatrix[x] = [];
      for (let y = 0; y < Y_TILES; y++) {
        const screenX = this.screenX(x);
        const screenY = this.screenY(y);

        const tileSprite = this.add
          .sprite(screenX + TILE_SIZE / 2, screenY + TILE_SIZE / 2, 'n_2', 0)
          .setScale(TILE_SCALE)
          .setActive(false)
          .setVisible(false);

        if (APPLY_TINT) tileSprite.setTint(TINTS[0]);

        this.tileMatrix[x][y] = {
          sprite: tileSprite,
          n: 0,
        };
      }
    }

    this.swipe = this.rexGestures.add.swipe({
      enable: true,
      dir: '4dir',
      threshold: 10,
    });

    this.swipe.on(
      'swipe',
      function (s, gameObject, lastPointer) {
        let moved = false;

        if (s.up) {
          moved = this.move('up');
        } else if (s.down) {
          moved = this.move('down');
        } else if (s.right) {
          moved = this.move('right');
        } else if (s.left) {
          moved = this.move('left');
        }

        this.moving = moved;
      },
      this
    );

    this.loading = false;
  }

  create() {
    this.leftKey = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.LEFT
    );
    this.rightKey = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.RIGHT
    );
    this.upKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.UP);
    this.downKey = this.input.keyboard.addKey(
      Phaser.Input.Keyboard.KeyCodes.DOWN
    );

    this.loadNFTTiles(() => {
      this.loadGame();
      this.startGame();
    });
  }

  update() {
    if (
      this.loading ||
      this.gameOver ||
      this.won ||
      this.moving ||
      this.tilesMoving > 0
    ) {
      return;
    }

    let moved = false;

    if (Phaser.Input.Keyboard.JustDown(this.leftKey)) {
      moved = this.move('left');
    } else if (Phaser.Input.Keyboard.JustDown(this.rightKey)) {
      moved = this.move('right');
    } else if (Phaser.Input.Keyboard.JustDown(this.upKey)) {
      moved = this.move('up');
    } else if (Phaser.Input.Keyboard.JustDown(this.downKey)) {
      moved = this.move('down');
    }

    if (moved) {
      this.moving = true;
    }
  }

  screenX(tileX) {
    return tileX * TILE_SIZE + (tileX + 1) * TILE_SPACING;
  }

  screenY(tileY) {
    return tileY * TILE_SIZE + (tileY + 1) * TILE_SPACING;
  }

  clearTile(x, y) {
    const tile = this.tileMatrix[x][y];
    tile.sprite.stop();
    tile.sprite.setActive(false).setVisible(false);
    if (APPLY_TINT) tile.sprite.setTint(TINTS[0]);
    tile.n = 0;
  }

  setTile(x, y, number) {
    const tile = this.tileMatrix[x][y];
    if (number === 0) {
      tile.sprite.stop().setActive(false).setVisible(false);
    } else {
      const textureName = `n_${number}`;
      if (!tile.sprite.active || tile.sprite.texture.key !== textureName) {
        tile.sprite.setActive(true).setVisible(true);
        tile.sprite.setTexture(textureName);
        tile.sprite.play(`animate_${textureName}`);
      }
    }
    if (APPLY_TINT) tile.sprite.setTint(TINTS[~~Math.log2(number) - 1]);
    tile.n = number;
  }

  getTileNumber(x, y) {
    return this.tileMatrix[x][y].n;
  }

  clearTiles() {
    for (let i = 0; i < TOTAL_TILES; i++) {
      this.clearTile(i % X_TILES, (i / X_TILES) >> 0);
    }
  }

  getRow(y) {
    return Array(X_TILES)
      .fill()
      .map((_, x) => ({
        id: x,
        n: this.getTileNumber(x, y),
        upgraded: false,
      }));
  }

  getCol(x) {
    return Array(Y_TILES)
      .fill()
      .map((_, y) => ({
        id: y,
        n: this.getTileNumber(x, y),
        upgraded: false,
      }));
  }

  onMovementFinished() {
    if (this.moving) {
      this.spawnTile();

      if (!this.canMove()) {
        this.stopGame();
      }

      this.moving = false;
    }
  }

  moveTile(srcX, srcY, dstX, dstY, number, upgraded) {
    if (srcX === dstX && srcY === dstY && !upgraded) {
      return;
    }

    const textureName = `n_${number}`;

    const sprite = this.add
      .sprite(
        this.screenX(srcX) + TILE_SIZE * 0.5,
        this.screenY(srcY) + TILE_SIZE * 0.5,
        textureName
      )
      .setScale(TILE_SCALE)
      .play(`animate_${textureName}`);

    if (APPLY_TINT) sprite.setTint(TINTS[~~Math.log2(number) - 1]);

    this.tilesMoving++;

    this.tweens.add({
      targets: sprite,
      x: this.screenX(dstX) + TILE_SIZE * 0.5,
      y: this.screenY(dstY) + TILE_SIZE * 0.5,
      ease: Phaser.Math.Easing.Bounce.InOut,
      duration: 100,
      onComplete: () => {
        sprite.destroy(true);
        this.setTile(dstX, dstY, number);
        this.tilesMoving--;
        if (this.tilesMoving === 0) {
          // This was the last tile moving
          this.onMovementFinished();
        }
        if (upgraded) this.animateTile(dstX, dstY, false);
      },
    });
  }

  setRow(y, line) {
    line.forEach((t, x) => {
      this.tileMatrix[x][y].n = t.n;
    });
    line.forEach((t, x) => {
      const srcX = t.id;
      if (srcX >= 0) {
        this.moveTile(srcX, y, x, y, t.n, t.upgraded);
      } else {
        this.setTile(x, y, t.n);
        if (t.upgraded) this.animateTile(x, y, false);
      }
    });
  }

  setCol(x, line) {
    line.forEach((t, y) => {
      this.tileMatrix[x][y].n = t.n;
    });
    line.forEach((t, y) => {
      const srcY = t.id;
      if (srcY >= 0) {
        this.moveTile(x, srcY, x, y, t.n, t.upgraded);
      } else {
        this.setTile(x, y, t.n);
        if (t.upgraded) this.animateTile(x, y, false);
      }
    });
  }

  reverseLine(line) {
    return [...line].reverse();
  }

  padLine(line, len) {
    return [
      ...line,
      ...Array(len - line.length).fill({ id: -1, n: 0, upgraded: false }),
    ];
  }

  moveLine(line, len) {
    return this.padLine(
      line.filter((t) => t.n !== 0),
      len
    );
  }

  mergeLine(line) {
    let merged = [];
    for (let i = 0; i < line.length && line[i].n !== 0; i++) {
      const t = { id: line[i].id, n: line[i].n, upgraded: false };
      if (i < line.length - 1 && t.n === line[i + 1].n) {
        t.n <<= 1;
        t.upgraded = true;
        this.increaseScore(t.n);
        i++;
      }
      merged.push(t);
    }
    return merged.length === 0 ? line : this.padLine(merged, line.length);
  }

  lineEquals(line1, line2) {
    return (
      line1.length === line2.length && line1.every((t, i) => t.n === line2[i].n)
    );
  }

  increaseScore(amount) {
    this.score += amount;

    this.emitEvent({
      eventType: EVENT_SCORE_UPDATED,
      score: this.score,
    });

    if (amount === WIN_TARGET_SCORE) {
      this.won = true;
      this.emitEvent({
        eventType: EVENT_GAME_WON,
        score: this.score,
      });
    }
  }

  animateTile(x, y, spawn) {
    if (this.getTileNumber(x, y) === 0) {
      return;
    }

    const tileSprite = this.tileMatrix[x][y].sprite;

    if (spawn) {
      tileSprite.setScale(TILE_SCALE * 0.5);
    }

    const t = this.tweens.createTimeline();

    const at = (s, d) =>
      t.add({
        targets: tileSprite,
        scale: s,
        ease: Phaser.Math.Easing.Bounce.InOut,
        duration: d,
      });

    if (spawn) {
      at(TILE_SCALE, ANIM_TIME_MS);
    } else {
      at(TILE_SCALE * 1.05, ANIM_TIME_MS / 2);
      at(TILE_SCALE, 12, ANIM_TIME_MS / 2);
    }

    t.play();
  }

  getAvailablePositions() {
    return Array.from(Array(TOTAL_TILES).keys())
      .map((i) => ({ x: i % X_TILES, y: (i / X_TILES) >> 0 }))
      .filter((p) => this.getTileNumber(p.x, p.y) === 0);
  }

  canMove() {
    for (let x = 0; x < X_TILES; x++) {
      for (let y = 0; y < Y_TILES; y++) {
        const n = this.getTileNumber(x, y);
        if (
          n === 0 ||
          (x < X_TILES - 1 && n === this.getTileNumber(x + 1, y)) ||
          (y < Y_TILES - 1 && n === this.getTileNumber(x, y + 1))
        ) {
          return true;
        }
      }
    }
    return false;
  }

  spawnTile(number) {
    const availablePositions = this.getAvailablePositions();
    if (availablePositions.length === 0) {
      return;
    }

    const randomIndex = ~~(Math.random() * availablePositions.length);
    const spawnPos = availablePositions[randomIndex];

    number =
      number !== undefined
        ? number
        : Math.random() <= FOUR_SPAWN_PERCENT
        ? 4
        : 2;

    this.setTile(spawnPos.x, spawnPos.y, number);
    this.animateTile(spawnPos.x, spawnPos.y, true);
  }

  move(dir) {
    const isHorizontal = dir === 'left' || dir === 'right';
    const shouldReverse = dir === 'right' || dir === 'down';

    const outerEnd = isHorizontal ? Y_TILES : X_TILES;
    const lineLen = isHorizontal ? X_TILES : Y_TILES;

    let moved = false;

    for (let i = 0; i < outerEnd; i++) {
      const origLine = isHorizontal ? this.getRow(i) : this.getCol(i);

      let startLine = shouldReverse ? this.reverseLine(origLine) : origLine;

      let newLine = this.moveLine(startLine, lineLen);
      newLine = this.mergeLine(newLine);
      newLine = shouldReverse ? this.reverseLine(newLine) : newLine;

      if (!moved && !this.lineEquals(origLine, newLine)) {
        moved = true;
      }

      if (isHorizontal) {
        this.setRow(i, newLine);
      } else {
        this.setCol(i, newLine);
      }
    }

    return moved;
  }

  startGame() {
    this.gameOver = false;
    this.score = 0;

    this.clearTiles();
    if (globalStartingTile === 0) {
      this.spawnTile();
    } else {
      this.spawnTile(globalStartingTile);
    }

    for (let i = 0; i < STARTING_TILES_COUNT - 1; i++) {
      this.spawnTile();
    }

    this.emitEvent({ eventType: EVENT_GAME_STARTED });
  }

  stopGame() {
    this.gameOver = true;
    this.emitEvent({
      eventType: EVENT_GAME_OVER,
      score: this.score,
    });
    console.log('Game over');
  }
}

const defaultGameConfig = {
  type: Phaser.AUTO,
  width: SCREEN_WIDTH,
  height: SCREEN_HEIGHT,
  pixelArt: false,
  backgroundColor: BACKGROUND_COLOR,
  physics: {
    default: 'arcade',
    arcade: {
      debug: false,
      gravity: {
        y: 0,
      },
    },
  },
  scene: [GameScene],
  inputKeyboard: true,
  inputTouch: true,
  inputMouse: true,
  hideBanner: true,
  hidePhaser: true,
  clearBeforeRender: true,
  failIfMajorPerformanceCaveat: true,
  antialiasing: false,
  batchSize: 1024,
  maxTexture: 8,
};

/**
 * This function must be called to create the game instance.
 *
 * @param {*} canvasId Id of the canvas element to use. If not specified
 * Phaser will create a new one.
 * @param {*} baseURL the website base URL
 * @param {*} tilesData tiles image data, fetched from NFTs
 * @param {*} startingTile starting tile number
 * @param {*} customConfig an optional Phaser config object which can be used
 * to extend default configuration
 */
export function createGame(
  canvasId,
  baseURL,
  tilesData,
  startingTile,
  eventCallback,
  customConfig
) {
  let configToUse = defaultGameConfig;

  if (canvasId) {
    const gameCanvas = document.getElementById(canvasId);

    configToUse = {
      ...configToUse,
      type: APPLY_TINT ? Phaser.WEBGL : Phaser.CANVAS,
      canvas: gameCanvas,
    };
  }

  if (customConfig) {
    configToUse = { ...configToUse, ...customConfig };
  }

  globalStartingTile = startingTile;
  globalTiles = tilesData;
  globalBaseURL = baseURL || DEFAULT_BASE_URL;
  globalEventCallback = eventCallback;

  window.game = new Phaser.Game(configToUse);
}

export function restartGame() {
  window.game.scene.keys.default.startGame();
}
